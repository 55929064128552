import React from "react";
import Tilty from "react-tilty";
import { AnimationOnScroll } from "react-animation-on-scroll";

const BcrumbBannerOne = ({ title, paragraph, styleClass, mainThumb }) => {
    return (
        <div className="breadcrum-area breadcrumb-banner">
            <div className="container">
                <div className="section-heading heading-left">
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={150}
                        animateIn="slideInLeft"
                    >
                        <h1
                            className="title h2"
                            dangerouslySetInnerHTML={{ __html: title }}
                        ></h1>
                        <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                    </AnimationOnScroll>
                </div>
                <div
                    className={`banner-thumbnail about_us_banner_main_image ${styleClass}`}
                >
                    <Tilty perspective={2000} style={{ marginTop: "-30px" }}>
                        <AnimationOnScroll
                            animateOnce={true}
                            delay={150}
                            animateIn="fadeInUp"
                        >
                            <img
                                src={process.env.PUBLIC_URL + mainThumb}
                                alt="Illustration"
                                style={{ marginTop: "-30px" }}
                            />
                        </AnimationOnScroll>
                    </Tilty>
                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={50}
                        animateIn="slideInLeft"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/others/bubble-9.png"
                            }
                            alt="Bubble"
                        />
                    </AnimationOnScroll>
                </li>
                <li
                    className="shape shape-2"
                    style={{
                        top: "48%",
                        right: "0",
                        transform: "translateY(-50%)",
                    }}
                >
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={50}
                        animateIn="slideInRight"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/others/bubble-21.png"
                            }
                            alt="Bubble"
                        />
                    </AnimationOnScroll>
                </li>
                <li
                    className="shape shape-3"
                    style={{
                        top: "57%",
                        left: "0",
                        transform: "translateY(25%)",
                    }}
                >
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={150}
                        animateIn="fadeInUp"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/others/line-4.png"
                            }
                            alt="Line"
                        />
                    </AnimationOnScroll>
                </li>
            </ul>
        </div>
    );
};

export default BcrumbBannerOne;
