import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Tilty from 'react-tilty';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const CtaLayoutOne = () => {
    const navigate = useNavigate();
    return (

        <div className="section call-to-action-area">
            <div className="container">
                <div className="call-to-action">
                    <div className="section-heading heading-light">
                        <p className="subtitle">Let's Work Together</p>
                        <h2 className="title">Need a successful project?</h2>
                            <a className="axil-btn btn-large btn-fill-white pointer"
                                onClick={() => {
                                    // check if on home page
                                    if (window.location.pathname === '/contact') {
                                        document.getElementById('contact_us_form').scrollIntoView({ behavior: 'smooth' });
                                    } else {
                                        navigate('/contact');
                                        setTimeout(() => {
                                            document.getElementById('contact_us_form').scrollIntoView({ behavior: 'smooth' });
                                        }, 0);
                                    }

                                }}
                            >
                                Estimate Project
                            </a>
                    </div>
                    <div className="thumbnail">
                        <div className="larg-thumb" data-sal="zoom-in" data-sal-duration="600" data-sal-delay="100">
                            <AnimationOnScroll animateOnce={true} animateIn="zoomIn" delay={100} duration={2}>
                                <Tilty perspective={3000}>
                                    <img className="paralax-image" src={process.env.PUBLIC_URL + "/images/others/chat-group.png"} alt="Chat" />
                                </Tilty>
                            </AnimationOnScroll>
                        </div>
                        <ul className="list-unstyled small-thumb">
                            <li className="shape shape-1" data-sal="slide-right" data-sal-duration="800" data-sal-delay="400">
                                <AnimationOnScroll animateOnce={true} animateIn="slideInRight" delay={300} duration={2}>
                                    <Tilty perspective={3000}>
                                        <img className="paralax-image" src={process.env.PUBLIC_URL + "/images/others/laptop-poses.png"} alt="Laptop" />
                                    </Tilty>
                                </AnimationOnScroll>
                            </li>
                            <li className="shape shape-2" data-sal="slide-left" data-sal-duration="800" data-sal-delay="300">
                                <AnimationOnScroll animateOnce={true} animateIn="slideInLeft" delay={300} duration={1.5}>
                                    <Tilty perspective={3000}>
                                        <img className="paralax-image" src={process.env.PUBLIC_URL + "/images/others/bill-pay.png"} alt="Bill" />
                                    </Tilty>
                                </AnimationOnScroll>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-9">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-12.png"} alt="Comments" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"} alt="Comments" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Comments" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"} alt="Comments" /></li>
                <li className="shape shape-7"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
            </ul>
        </div>
    )

}

export default CtaLayoutOne;