import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Innovation",
        para: "We believe that creativity and new technologies are important for developing successful software solutions."
    },
    {
        id: 2,
        title: "Collaboration",
        para: "We value working closely with our clients throughout the entire development process, from idea to launch and beyond."
    },
    {
        id: 3,
        title: "Quality",
        para: "We are dedicated to delivering software that is reliable, user-friendly, and of the highest quality."
    },
    {
        id: 4,
        title: "Adaptability",
        para: "We understand that the digital world is constantly changing, and we aim to adapt and improve with it by staying up-to-date with the latest trends and technologies."
    },
    {
        id: 5,
        title: "Integrity",
        para: "We believe in being honest and transparent with our clients and delivering on our promises in a professional and trustworthy manner."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Why should you work with us?"
                    description="Our commitment to quality, innovation, and customer satisfaction drives everything we do, and we take great pride in the lasting relationships we build with our clients."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;