import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Pagination, Autoplay, EffectFade } from "swiper";

SwiperCore.use([Mousewheel, Pagination, Autoplay, EffectFade]);

const SwiperCarousel = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState();
    const swiperRef = useRef(null);

    return (
        // <div className="d-flex items-center">
            <Swiper
                ref={swiperRef}
                // slidesPerView={1}
                // pagination={{
                //     clickable: true,
                // }}
                loop={true}
                autoplay={{
                    delay: 3000,
                }}
                effect="fade"
                // initialSlide={0}
                // centeredSlides={true}
                // grabCursor={true}
                // navigation={true}
                modules={[Autoplay]}
                onSwiper={setSwiperInstance}
                onSlideChange={(e) => {
                    setActiveIndex(e.activeIndex);
                }}
            >
                {
                    images.map((image, index) => (
                        <SwiperSlide 
                            key={index}
                            onClick={() => {
                                setActiveIndex(index);
                                swiperRef.current.swiper.slideTo(index);
                            }}
                        >
                            <img src={image} alt="image" 
                                style={{ borderRadius: "38px" }}
                                className="non_mobile"
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        // </div>
    )
}

export default SwiperCarousel;