import React from 'react';
import Tilty from 'react-tilty';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import SwiperCarousel from '../../component/carousel/SwiperCarousel';
import SwiperCarouselMobile from '../../component/carousel/SwiperCarouselMobile';

const BcrumbBannerTwo = ({title, paragraph, mainThumb, images=[]}) => {
    return (
        <div className="breadcrum-area breadcrumb-banner single-breadcrumb">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="section-heading heading-left">
                            <AnimationOnScroll animateOnce={true} delay={150} animateIn="slideInRight">
                                <h1 className="title h2" dangerouslySetInnerHTML={{__html: title}}></h1>
                                <p dangerouslySetInnerHTML={{__html: paragraph}}></p>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    {
                        images.length > 0 ? 
                            <>
                                <AnimationOnScroll animateOnce={true} delay={150} animateIn="slideInLeft">
                                <div className="d-block d-sm-none">
                                    <Tilty perspective={2000} reset={false}>
                                        <SwiperCarouselMobile images={images} />
                                    </Tilty>
                                </div>

                                <div className="d-none d-sm-block">
                                    <Tilty perspective={2000} reset={false}>
                                        <SwiperCarousel images={images} />
                                    </Tilty>
                                </div>
                                </AnimationOnScroll>
                            </>
                            :
                            <div className="banner-thumbnail">
                                <Tilty perspective={2000} reset={false}>
                                    <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" />
                                </Tilty>
                            </div>
                    }
                    </div>
                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-20.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                </li>
            </ul>
        </div>
    )
}

export default BcrumbBannerTwo;
