import React, { useState, useEffect } from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';


const ColorSwitcher = () => {
    const [switcher, setSwitcher] = useState(undefined);

    useEffect(() => {
        const darkMode = localStorage.getItem('darkMode');
        setSwitcher(darkMode === 'true');
    }, []);

    const switchColor = () => {
        document.querySelector('body').classList.toggle("active-dark-mode");
        localStorage.setItem('darkMode', !switcher);
        setSwitcher(!switcher);
        
    }

    return (
        <div className="my_switcher d-none d-lg-block">
            <button onClick={switchColor}>
                <span className="setColor dark"><FaLightbulb /></span>
                <span className="setColor light"><FaMoon /></span>
            </button>
        </div>
    )
}

export default ColorSwitcher;