import React from 'react'
import PropTypes from "prop-types";
import { WEBSITE_NAME } from "../data/website/websiteData";


const SEO = ( {title} ) => {
        return (
            <>

            </>
        );
    }
    SEO.propTypes = {
        title: PropTypes.string
    };

    export default SEO;
