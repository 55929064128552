import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SectionTitle from "../../elements/section-title/SectionTitle";
import FaqData from "../../data/faq/FaqData.json";
import { AnimationOnScroll } from "react-animation-on-scroll";

const FaqOne = () => {
    const [filterData, setFilterData] = useState(FaqData);
    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchValue(searchValue);
        const filterData = FaqData.filter((data) => {
            return data.title.toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilterData(filterData);
    };

    return (
        <div className="section section-padding-equal bg-color-light faq-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xl-4">
                        <SectionTitle
                            subtitle="FAQs"
                            title="Frequently Asked Questions"
                            description="Expert solutions for seamless digital transformation."
                            textAlignment="heading-left"
                            textColor=""
                        />
                    </div>

                    <div className="col-lg-7 col-xl-8">
                        <AnimationOnScroll
                            animateOnce={true}
                            delay={50}
                            animateIn="fadeInUp"
                        >
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="form-control"
                                    style={{
                                        border: "1px solid #e5e5e5",
                                    }}
                                    value={searchValue}
                                    onChange={handleSearch}
                                />
                            </div>
                        </AnimationOnScroll>

                        {filterData.length > 0 ? (
                            <>
                                <AnimationOnScroll
                                    animateOnce={true}
                                    delay={50}
                                    animateIn="fadeInUp"
                                >
                                    {searchValue ? (
                                        <p className="faq-result-count">
                                            {filterData.length} result found
                                        </p>
                                    ) : (
                                        <p className="faq-result-count"></p>
                                    )}

                                    <div
                                        className="faq-accordion"
                                        style={{ marginTop: "-20px" }}
                                    >
                                        <Accordion
                                            defaultActiveKey={filterData[0].id}
                                        >
                                            {filterData.map((data) => (
                                                <Accordion.Item
                                                    eventKey={data.id}
                                                    key={data.id}
                                                >
                                                    <Accordion.Header>
                                                        <span>{`${data.id}.`}</span>
                                                        {data.title}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>{data.body}</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                </AnimationOnScroll>
                            </>
                        ) : (
                            <p className="faq-result-count">No result found</p>
                        )}
                    </div>
                </div>
            </div>
            
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1">
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={50}
                        animateIn="fadeInDown"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/others/bubble-7.png"
                            }
                            alt="Bubble"
                        />
                    </AnimationOnScroll>
                </li>

                <li className="shape shape-2">
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={50}
                        animateIn="fadeInUp"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/others/line-4.png"
                            }
                            alt="line"
                        />
                    </AnimationOnScroll>
                </li>
                <li className="shape shape-4">
                    <AnimationOnScroll
                        animateOnce={true}
                        delay={50}
                        animateIn="fadeInLeft"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/others/faq-person.png"
                            }
                            style={{ width: "447px" }}
                            alt="Poses"
                        />
                    </AnimationOnScroll>
                </li>
            </ul>
        </div>
    );
};

export default FaqOne;
