import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { 
     FaPhone, FaFax 
} from "react-icons/fa";

import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { useAlert } from 'react-alert'

import { WEBSITE_EMAILS, SOCIAL_MEDIA_LINKS, WEBSITE_COMPANY_ADDRESSES, WEBSITE_PHONE_NUMBERS, ShareComponent } from '../../data/website/websiteData';

const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    const alert = useAlert();
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    {/* <div className="col-lg-4 col-xl-5">
                        <ul className="main-navigation list-unstyled">
                        {
                                SOCIAL_MEDIA_LINKS.map((socialMedia, index) => {
                                    return (
                                        <li key={index} className='contactAddress'>
                                            <a href={socialMedia.link} target="_blank" rel='noopener noreferrer'>
                                                {socialMedia.name} {socialMedia.icon}
                                            </a>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    </div> */}

                    {/* <div className="col-lg-8 col-xl-7"> */}
                    
                    <div className="col-lg-12 col-xl-12">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <ReactTooltip id="copyAddress" />
                                    <CopyToClipboard 
                                        text={WEBSITE_COMPANY_ADDRESSES[0].address}
                                        onCopy={() => {
                                            alert.success("Copied to clipboard");
                                        }}
                                    >
                                        <p data-tip="Click to Copy Address" data-for="copyAddress"
                                            className="email contactAddress"
                                        >
                                            {WEBSITE_COMPANY_ADDRESSES[0].address.substring(0, 18)}
                                            <br />
                                            {WEBSITE_COMPANY_ADDRESSES[0].address.substring(18, WEBSITE_COMPANY_ADDRESSES[0].address.length)}
                                        </p>
                                    </CopyToClipboard>

                                </address>
                                <address className="address">
                                    <span className="title">General Support</span>
                                    <ReactTooltip id="copyEmail" />
                                    <CopyToClipboard
                                        text={WEBSITE_EMAILS[1]}
                                        onCopy={() => {
                                            alert.success("Copied to clipboard");
                                        }}
                                        >
                                        <a href={`mailto:${WEBSITE_EMAILS[0]}`} className="email contactAddress" 
                                            data-tip="Click to Copy and Send Mail" data-for="copyEmail"
                                        >
                                            { WEBSITE_EMAILS[0] }
                                        </a>
                                    </CopyToClipboard>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <ReactTooltip id="copyPhone" />
                                    <CopyToClipboard
                                        text={WEBSITE_PHONE_NUMBERS[0]}
                                        onCopy={() => {
                                            alert.success("Copied to clipboard");
                                        }}
                                    >
                                        <a href={`tel:${WEBSITE_PHONE_NUMBERS[0]}`} className="tel contactAddress" 
                                            data-tip="Click to Copy Phone Number" 
                                            data-for="copyPhone"
                                        >
                                            <FaPhone /> {WEBSITE_PHONE_NUMBERS[0]}
                                        </a>
                                    </CopyToClipboard>
                                    {/* <ReactTooltip id="copyFax" />
                                    <CopyToClipboard
                                        text={WEBSITE_PHONE_NUMBERS[1]}
                                        onCopy={() => {
                                            alert.success("Copied to clipboard");
                                        }}
                                    >
                                        <a href={`tel:${WEBSITE_PHONE_NUMBERS[1]}}`} className="tel contactAddress"
                                            data-tip="Click to Copy Phone Number"
                                            data-for="copyFax"
                                        >
                                            <FaFax /> {WEBSITE_PHONE_NUMBERS[1]}
                                        </a>
                                    </CopyToClipboard> */}
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Share Us</h5>
                                <div className="contact-social-share">
                                    <ShareComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
