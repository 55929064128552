import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
// import { slugify } from '../../utils';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { WEBSITE_NAME } from '../../data/website/websiteData';

// const icons = [
//     { id: 1, icon: <FaFacebookF />, link: 'https://facebook.com/' },
//     { id: 2, icon: <FaTwitter />, link: 'https://twitter.com/' },
//     { id: 3, icon: <FaPinterestP />, link: 'https://www.pinterest.com/' },
//     { id: 4, icon: <FaLinkedin />, link: 'https://www.linkedin.com/' },
//     { id: 5, icon: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { id: 6, icon: <FaVimeoV />, link: 'https://vimeo.com/' },
//     { id: 7, icon: <FaDribbble />, link: 'https://dribbble.com/' },
//     { id: 8, icon: <FaBehance />, link: 'https://www.behance.net/' },
// ]

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    const navigate = useNavigate();
    return (
      <footer className={`footer-area ${parentClass}`}>
        <div className='container'>
          {/* <div className='footer-top'>
            <div>
              <ul
                className='list-unstyled'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0 -5px",
                }}
              >
                {icons.map((data) => (
                  <li key={data.id}>
                    <AnimationOnScroll
                      animateIn='fadeInUp'
                      animateOnce={true}
                      delay={data.id * 100}
                    >
                      <a
                        className='pointer custom_footer-social-anchor'
                        href={data.link}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        {data.icon}
                      </a>
                    </AnimationOnScroll>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
          <div className='footer-main'>
            <div className='row'>
              <div className='col-xl-6 col-lg-5'>
                <AnimationOnScroll animateIn='slideInLeft' animateOnce={true}>
                  <div className='footer-widget border-end'>
                    <div className='footer-newsletter'>
                      <h2 className='title'>Get in touch!</h2>
                      <p>
                        Our company was founded with the goal of helping
                        businesses succeed through exceptional technology and
                        design services. We are a team of dedicated
                        professionals who bring years of experience and a deep
                        understanding of the latest technologies and industry
                        trends to every project.
                      </p>
                      <form>
                        <div className='input-group'>
                          <span className='mail-icon'>
                            <FaEnvelopeOpen />{" "}
                          </span>
                          <input
                            type='email'
                            className='form-control'
                            placeholder='Email address'
                          />
                          <button className='subscribe-btn' type='submit'>
                            Subscribe
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className='col-xl-6 col-lg-7'>
                <div className='row'>
                  <AnimationOnScroll
                    animateIn='slideInRight'
                    animateOnce={true}
                    className='d-flex'
                  >
                    <div className='col-sm-6'>
                      <div className='footer-widget'>
                        <h6 className='widget-title'>Services</h6>
                        <div className='footer-menu-link'>
                          <ul className='list-unstyled'>
                            {getServiceData.slice(0, 6).map((data, index) => (
                              <li key={index}>
                                {/* <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link> */}
                                <Link to='#'>{data.title}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <div className='footer-widget'>
                        <h6 className='widget-title'>Resourses</h6>
                        <div className='footer-menu-link'>
                          <ul className='list-unstyled'>
                            {/* <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li> */}
                            {/* <li> <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Studies</Link></li> */}
                            {/* <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Portfolio</Link></li> */}
                            {/* <li>
                              <Link to='#'>Blog</Link>
                            </li> */}
                            <li>
                              <Link to='#'>Portfolio</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <div className='footer-widget'>
                        <h6 className='widget-title'>Support</h6>
                        <div className='footer-menu-link'>
                          <ul className='list-unstyled'>
                            <li>
                              <a
                                className='pointer'
                                onClick={() => {
                                  navigate("/contact");
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Contact
                              </a>
                            </li>
                            <li>
                              <a
                                className='pointer'
                                onClick={() => {
                                  navigate("/privacy-policy");
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a
                                className='pointer'
                                onClick={() => {
                                  navigate("/terms-use");
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Terms of Use
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='footer-copyright'>
                  <span className='copyright-text'>
                    © {new Date().getFullYear()}. All rights reserved by{" "}
                    {WEBSITE_NAME}.
                  </span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='footer-bottom-link'>
                  <ul className='list-unstyled'>
                    <li>
                      <a
                        className='pointer'
                        onClick={() => {
                          navigate("/privacy-policy");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        className='pointer'
                        onClick={() => {
                          navigate("/terms-use");
                          window.scrollTo(0, 0);
                        }}
                      >
                        Terms of Use
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default FooterOne;