import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaRedditAlien,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaTelegramPlane,
} from "react-icons/fa";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";

export const WEBSITE_NAME = "Nova Dev Solutions";
export const WEBSITE_ACRONYM = "NDS";
export const WEBSITE_URL = "https://www.novadevsol.com/";
export const WEBSITE_EMAILS = ["novadevsol@gmail.com", "ch.arham1220@gmail.com"];
export const WEBSITE_PHONE_NUMBERS = [
  "+92 336 6541111",
  "+44 800 123 456",
  "+61 800 123 456",
  "+92 300 123 4567",
];

export const SOCIAL_MEDIA_LINKS = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/",
    icon: <FaFacebookF />,
  },
  {
    name: "Twitter",
    link: "https://www.twitter.com/",
    icon: <FaTwitter />,
  },
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/",
    icon: <FaLinkedinIn />,
  },
  {
    name: "Reddit",
    link: "https://www.reddit.com/",
    icon: <FaRedditAlien />,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/",
    icon: <FaInstagramSquare />,
  },
  {
    name: "Whatsapp",
    link: "https://www.whatsapp.com/",
    icon: <FaWhatsappSquare />,
  },
  {
    name: "Telegram",
    link: "https://www.telegram.com/",
    icon: <FaTelegramPlane />,
  },
];

export const WEBSITE_COMPANY_ADDRESSES = [
  // {
  //   title: "Illinios-HQ",
  //   thumb: "/images/others/location-1.png",
  //   address: "Illinios: 1010 W Springfield Apt 301, Urbana, IL, 61801",
  //   phone: "+1 447 902 0758",
  //   email: "novadevsol@gmail.com",
  // },
  // {
  //   title: "London-UK",
  //   thumb: "/images/others/location-2.png",
  //   address: "P.O. Box 283 8562 Fusce Rd., Fringilla PC",
  //   phone: "+44 800 123 456",
  //   email: "london@gmail.com",
  // },
  // {
  //   title: "Melbourne-AU",
  //   thumb: "/images/others/location-3.png",
  //   address: "P.O. Box 283 8562 Fusce Rd., Fringilla PC",
  //   phone: "+61 800 123 456",
  //   email: "melbourne@gmail.com",
  // },
  {
    title: "Faisalabad-PK",
    thumb: "/images/others/location-4.png",
    address: "P 25 , 2nd Floor  Hajwairy Town  Sargodha road",
    phone: "+92 321 6660390",
    email: "novadevsol@gmail.com",
  },
];

// used in OffcanvasMenu.js
export const ShareComponent = () => {
  const linkToShare = WEBSITE_URL;
  return (
    <ul className='social-share list-unstyled'>
      <li>
        <a>
          <FacebookShareButton url={linkToShare}>
            <FaFacebookF />
          </FacebookShareButton>
        </a>
      </li>
      <li>
        <a>
          <TwitterShareButton url={linkToShare}>
            <FaTwitter />
          </TwitterShareButton>
        </a>
      </li>
      <li>
        <a>
          <LinkedinShareButton url={linkToShare}>
            <FaLinkedinIn />
          </LinkedinShareButton>
        </a>
      </li>
      <li>
        <a>
          <RedditShareButton url={linkToShare}>
            <FaRedditAlien />
          </RedditShareButton>
        </a>
      </li>
      <li>
        <a>
          <WhatsappShareButton url={linkToShare}>
            <FaWhatsappSquare />
          </WhatsappShareButton>
        </a>
      </li>
      <li>
        <a>
          <TelegramShareButton url={linkToShare}>
            <FaTelegramPlane />
          </TelegramShareButton>
        </a>
      </li>
    </ul>
  );
};
