import React from 'react';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';



const BreadCrumbOne = ({title, page, page_2, page_2_link}) => {
    return (
        <div className="breadcrum-area">
            <div className="container">
                <div className="breadcrumb">
                    <ul className="list-unstyled">
                        <li>
                            <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                        </li>
                        {
                            page_2 ? (
                                <>
                                    <li>
                                        <Link to={process.env.PUBLIC_URL + page_2_link}>{page}</Link>
                                    </li>
                                    <li className="active" dangerouslySetInnerHTML={{__html: page_2}}></li>
                                </>
                            ) : (
                                <li className="active" dangerouslySetInnerHTML={{__html: page}}></li>
                            )
                        }
                    </ul>
                    <h1 className="title h2" dangerouslySetInnerHTML={{__html: title}}></h1>
                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <AnimationOnScroll animateOnce={true} delay={50} animateIn="fadeInLeft">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" />
                    </AnimationOnScroll>
                </li>

                <li className="shape shape-2"
                    style={{top: '50%', right: '0', transform: 'translateY(-50%)'}}
                >
                    <AnimationOnScroll animateOnce={true} delay={100} animateIn="fadeInRight">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-11.png"} alt="Bubble"/>
                    </AnimationOnScroll>
                </li>

                <li className="shape shape-3"
                    style={{top: '50%', left: '0', transform: 'translateY(-25%)'}}
                >
                    <AnimationOnScroll animateOnce={true} delay={200} animateIn="fadeInUp">
                        <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                    </AnimationOnScroll>
                </li>
            </ul>
        </div>
    )
}

export default BreadCrumbOne;