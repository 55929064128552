import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CounterUp from "../../component/counterup/CounterUp";

const CounterUpOne = () => {
    return (
        <div className="section section-padding bg-color-dark">
            <div className="container">
                <SectionTitle
                    subtitle="Our History"
                    title="A Quick Look At What We’ve Delivered"
                    description="Dive into the success stories and gain insights into the strategies and outcomes that can inspire your own path to success."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row">
                    <CounterUp
                        colSize="col-lg-3 col-6"
                        layoutStyle=""
                        evenTopMargin=""
                    />
                </div>
            </div>
        </div>
    );
};

export default CounterUpOne;
