import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import TestimonialPropOne from "./TestimonialPropOne";

const TestimonialOne = () => {
    return (
        <div className="section section-padding">
            <div className="container">
                <SectionTitle
                    subtitle="Testimonials"
                    title="Hear From Our Clients"
                    description="We are very proud of the service we provide and stand by every product we carry. Read our testimonials from our happy customers."
                    textAlignment="heading-left"
                    textColor=""
                />
                <div className="row">
                    <TestimonialPropOne colSize="col-lg-4" itemShow="3" />
                </div>
            </div>
            <ul className="shape-group-4 list-unstyled">
                <li className="shape-1">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/images/others/bubble-1.png"
                        }
                        alt="Bubble"
                    />
                </li>
            </ul>
        </div>
    );
};

export default TestimonialOne;
