import React from "react";
import { useNavigate } from "react-router-dom";
import { slugify } from '../../../utils';
import { AnimationOnScroll } from "react-animation-on-scroll";

const PropOne = ({ projectStyle, portfolio }) => {
  const navigate = useNavigate();
  return (
    <>
      {portfolio.id % 2 !== 0 ? (
        <AnimationOnScroll
          animateIn="fadeInLeft"
          animateOnce={true}
          delay={100}
        >
          <div className={`project-grid ${projectStyle}`}>
            <div className="thumbnail">
              <a
                className="pointer"
                onClick={() => {
                  navigate(`/portfolio-details/${slugify(portfolio.title)}`);
                  window.scrollTo(0, 0);
                }}

              >
                <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
              </a>
            </div>
            <div className="content">
              <h4 className="title">
                <a
                  className="pointer"
                  onClick={() => {
                    navigate(`/portfolio-details/${slugify(portfolio.title)}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  {portfolio.title}
                </a>
              </h4>
              <span className="subtitle">
                {portfolio.category.map((cat, i) => (
                  <span key={i}>{cat}</span>
                ))}
              </span>
            </div>
          </div>
        </AnimationOnScroll>
      ) : (
        <AnimationOnScroll
          animateIn="fadeInRight"
          animateOnce={true}
          delay={100}
        >
          <div className={`project-grid ${projectStyle}`}>
            <div className="thumbnail">
              <a
                className="pointer"
                onClick={() => {
                  navigate(`/portfolio-details/${slugify(portfolio.title)}`);
                  window.scrollTo(0, 0);
                }}

              >
                <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
              </a>
            </div>
            <div className="content">
              <h4 className="title">
                <a
                    className="pointer"
                    onClick={() => {
                      navigate(`/portfolio-details/${slugify(portfolio.title)}`);
                      window.scrollTo(0, 0);
                    }}
                  >
                    {portfolio.title}
                  </a>
              </h4>
              <span className="subtitle">
                {portfolio.category.map((cat, i) => (
                  <span key={i}>{cat}</span>
                ))}
              </span>
            </div>
          </div>
        </AnimationOnScroll>
      )}
    </>
  );
};

export default PropOne;
