import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { WEBSITE_NAME, WEBSITE_EMAILS } from "../data/website/websiteData";

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">
                                            This Privacy policy was published on
                                            August 24th, 2023.
                                        </h5>
                                    </div>
                                    <h4>GDPR compliance</h4>
                                    <p>
                                        The General Data Protection Regulation
                                        is a Regulation in EU law on data
                                        protection and privacy in the EU and the
                                        European Economic Area. The GDPR is an
                                        important component of EU privacy law
                                        and of human rights law, in particular
                                        Article 8 of the Charter of Fundamental
                                        Rights of the European Union. sodales
                                        ut. In ultrices elit malesuada velit
                                        ornare, eget dictum velit hendrerit.
                                        Praesent bibendum blandit lectus, eu
                                        congue neque mollis in. Pellentesque
                                        metus diam, hendrerit in purus
                                        fringilla, accumsan bibendum sapien.
                                        Nunc non facilisis sem.
                                    </p>
                                    <h4>About {WEBSITE_NAME}</h4>
                                    <p>
                                        {WEBSITE_NAME} is a company that
                                        provides a range of services from web
                                        development, web design, blockchain
                                        application development, mobile
                                        application development, and digital
                                        marketing. {WEBSITE_NAME} is committed
                                        to protecting and respecting your
                                        privacy. This Privacy Policy sets out
                                        how we collect, use, and disclose
                                        information that identifies you or is
                                        associated with you (“personal
                                        information”) when you use our website
                                        and services. We will only use and
                                        disclose your personal information in
                                        accordance with this Privacy Policy.
                                    </p>
                                    <h4>
                                        When we collect personal data about you
                                    </h4>
                                    <p>
                                        When you visit the website, we collect
                                        your personal data when you make an
                                        enquiry through the website, contact us
                                        by email, phone or post, or otherwise
                                        communicate with us. We also collect
                                        your personal data when you buy our
                                        products or services. We also collect
                                        personal data when you use our website
                                        or services through cookies and similar
                                        technologies (e.g. web beacons and
                                        pixels).{" "}
                                    </p>
                                    <h4>
                                        Why we collect and use personal data
                                    </h4>
                                    <p className="mb--20">
                                        We collect and use personal data mainly
                                        to perform direct sales, direct
                                        marketing and customer service. We also
                                        collect data about suppliers, partners,
                                        and persons seeking a job or working in
                                        our company.
                                    </p>
                                    <p>
                                        We may use your information for the
                                        following purposes:
                                        <ul>
                                            <li>
                                                Send you marketing
                                                communications which you have
                                                requested. These may include
                                                information about our products
                                                and services, events,
                                                activities, and promotions of
                                                our associated partners’
                                                products and services. This
                                                communication is subscription
                                                based and requires your consent.
                                            </li>
                                            <li>
                                                Send you information about the
                                                products and services that you
                                                have acquired from us.
                                            </li>
                                            <li>
                                                Perform direct sales activities
                                                in cases where legitimate and
                                                mutual interest is established.
                                            </li>
                                            <li>
                                                Reply to a ‘Contact me’ or other
                                                web forms you have completed on
                                                one of our {WEBSITE_NAME}{" "}
                                                webpages (e.g. to download a
                                                whitepaper).
                                            </li>
                                            <li>
                                                Follow up on incoming requests
                                                (customer support, emails,
                                                chats, or phone calls).
                                            </li>
                                            <li>
                                                Perform contractual obligations
                                                such as order confirmation,
                                                license details, invoice,
                                                reminders, and similar. The
                                                contract may be with{" "}
                                                {WEBSITE_NAME} directly or with
                                                a {WEBSITE_NAME} partner.
                                            </li>
                                            <li>
                                                Notify you about any disruptions
                                                to our services (system
                                                messages).
                                            </li>
                                            <li>
                                                Contact you to conduct surveys
                                                about your opinion on our
                                                products and services.
                                            </li>
                                            <li>Process a job application.</li>
                                        </ul>
                                    </p>

                                    <h4>Type of personal data collected</h4>
                                    <p>
                                        The personal data we collect depends on
                                        whether you just visit our website or
                                        use our services. If you visit our
                                        website, you do not need to provide us
                                        with any personal data. However, your
                                        browser transmits some data
                                        automatically, such as the date and time
                                        of retrieval of one of our web pages,
                                        your browser type and settings, your
                                        operating system, the last web page you
                                        visited, the data transmitted and the
                                        access status, and your IP address.
                                    </p>

                                    <p>
                                        If you use our services, personal data
                                        is required to fulfill the requirements
                                        of a contractual or service
                                        relationship, which may exist between
                                        you and our organization. This includes
                                        <ul>
                                            <li>
                                                Contact details, including name,
                                                email, telephone number and
                                                address
                                            </li>
                                            <li>
                                                Provision of contractual
                                                services
                                            </li>
                                            <li>
                                                Contact form or other inquiries
                                            </li>
                                            <li>Comment function</li>
                                            <li>Cookies and tracking</li>
                                        </ul>
                                    </p>
                                    <h4>
                                        Information we collect automatically
                                    </h4>
                                    <p>
                                        We may automatically collect information
                                        when you visit, access, or use the
                                        website. This information may include
                                        some or all of the following
                                        <ul>
                                            <li>
                                                information about your device
                                                and internet connection,
                                                including your IP address,
                                                operating system, and browser
                                                type
                                            </li>
                                            <li>
                                                information about the
                                                application version you use
                                            </li>
                                            <li>
                                                information about your use of
                                                features on the website, such as
                                                search queries
                                            </li>
                                            <li>
                                                metadata and other information
                                                associated with other files
                                                stored on your device
                                            </li>
                                            <li>
                                                standard server log information
                                                (as it is the case for almost
                                                all websites)
                                            </li>
                                        </ul>
                                    </p>
                                    <h4>The use of cookies and web beacons</h4>
                                    <p className="mb--20">
                                        We may use cookies on our Site. Cookies
                                        are small text files that a website or
                                        its service provider transfers to your
                                        computer or other devices. They are
                                        widely used in order to make websites
                                        work, or work more efficiently, as well
                                        as to provide business and marketing
                                        information to the owners of the site.
                                        We use cookies to record current session
                                        information, but do not use permanent
                                        cookies. You are free to decline our
                                        cookies if your browser permits, but
                                        doing so may interfere with your use of
                                        our website. The help tab on the menu
                                        bar of most browsers will tell you how
                                        to prevent your browser from accepting
                                        new cookies, how to have the browser
                                        notify you when you receive a new
                                        cookie, or how to disable cookies
                                        altogether. For more information about
                                        cookies and web beacons, please visit{" "}
                                        <a
                                            href="https://www.allaboutcookies.org"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            www.allaboutcookies.org
                                        </a>
                                        .
                                    </p>
                                    <p className="mb--20">
                                        may log information using digital images
                                        called web beacons on our Site or in our
                                        emails.
                                    </p>
                                    <p>
                                        This information is used to make our
                                        Site work more efficiently, as well as
                                        to provide business and marketing
                                        information to the owners of the Site,
                                        and to gather such personal data as
                                        browser type and operating system,
                                        referring page, path through site,
                                        domain of ISP, etc. for the purposes of
                                        understanding how visitors use our Site.
                                        Cookies and similar technologies help us
                                        tailor our Site to your personal needs,
                                        as well as to detect and prevent
                                        security threats and abuse. If used
                                        alone, cookies and web beacons do not
                                        personally identify you.
                                    </p>
                                    <h4>How long we keep your data</h4>
                                    <p className="mb--20">
                                        We store personal data for as long as we
                                        find it necessary to fulfill the purpose
                                        for which the personal data was
                                        collected, while also considering our
                                        need to answer your queries or resolve
                                        possible problems, to comply with legal
                                        requirements under applicable laws, to
                                        attend to any legal claims/complaints,
                                        and for safeguarding purposes.
                                    </p>
                                    <h4>Your rights to your personal data</h4>
                                    <p>
                                        We store personal data for as long as we
                                        find it necessary to fulfill the purpose
                                        for which the personal data was
                                        collected, while also considering our
                                        need to answer your queries or resolve
                                        possible problems. This helps us to
                                        comply with legal requirements under
                                        applicable laws, to attend to any legal
                                        claims/complaints, and for safeguarding
                                        purposes.
                                    </p>
                                    <h4>{WEBSITE_NAME} privacy policy</h4>
                                    <p>
                                        {WEBSITE_NAME} operates the{" "}
                                        {WEBSITE_NAME} website, which provides
                                        the SERVICE. This page is used to inform
                                        website visitors regarding our policies
                                        with the collection, use, and disclosure
                                        of Personal Information if anyone
                                        decided to use our Service, the{" "}
                                        {WEBSITE_NAME} website. If you choose to
                                        use our Service, then you agree to the
                                        collection and use of information in
                                        relation with this policy. The Personal
                                        Information that we collect are used for
                                        providing and improving the Service. We
                                        will not use or share your information
                                        with anyone except as described in this
                                        Privacy Policy.
                                    </p>
                                    <h4>Changes to this Privacy Policy</h4>
                                    <p>
                                        We may update our Privacy Policy from
                                        time to time. We will notify you of any
                                        changes by posting the new Privacy
                                        Policy on this page. We will let you
                                        know via email and/or a prominent notice
                                        on our Service, prior to the change
                                        becoming effective and update the
                                        "effective date" at the top of this
                                        Privacy Policy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default PrivacyPolicy;
