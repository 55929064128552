import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { WEBSITE_COMPANY_ADDRESSES } from '../../data/website/websiteData';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { useAlert } from 'react-alert'

const allData = WEBSITE_COMPANY_ADDRESSES;

const ContactLocation = () => {
    const alert = useAlert();
    return (
        <>
            {allData.map((data, index) => (
                <div className="col-lg-3 col-sm-6" key={index}>
                    <AnimationOnScroll animateOnce={true} delay={(index+1) * 100} animateIn="fadeInUp">
                    <div className="office-location">
                        <div className="thumbnail img-hover-zoom_contact">
                            <img src={process.env.PUBLIC_URL + data.thumb} alt="Office" />
                        </div>
                        <div className="content">
                            <h4 className="title">{data.title}</h4>
                            <p>
                                <ReactTooltip id="copyAddress" />
                                <CopyToClipboard text={data.address}
                                    onCopy={() => alert.success("Address copied to clipboard")}
                                >
                                    <span className="contactAddress"
                                        data-tip="Click to copy address"
                                        data-for="copyAddress"
                                    >
                                        {data.address}
                                    </span>
                                </CopyToClipboard>

                                <ReactTooltip id="copyEmail" />
                                <CopyToClipboard text={data.email}
                                    onCopy={() => alert.success("Email copied to clipboard")}
                                >
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            marginTop: "10px",
                                            fontSize: "0.9rem"
                                        }}
                                    >
                                        <a href={`mailto:${data.email}`}
                                            style={{
                                                color: 'inherit'
                                            }}
                                            className="contactAddress"
                                            data-tip="Click to copy email"
                                            data-for="copyEmail"
                                        >
                                            <FaEnvelope />&nbsp;&nbsp;
                                            {data.email}
                                        </a>
                                    </span>
                                </CopyToClipboard>

                                <ReactTooltip id="copyPhone" />
                                <CopyToClipboard text={data.phone}
                                    onCopy={() => alert.success("Phone copied to clipboard")}
                                >
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <a href={`tel:${data.phone}`}
                                            style={{
                                                color: 'inherit'
                                            }}
                                            className="contactAddress"
                                            data-tip="Click to copy phone"
                                            data-for="copyPhone"
                                        >
                                            <FaPhoneAlt />&nbsp;&nbsp;
                                            {data.phone}
                                        </a>
                                    </span>
                                </CopyToClipboard>
                            </p>
                        </div>
                    </div>
                    </AnimationOnScroll>
                </div>
            ))}
        </>
    )
}

export default ContactLocation;