import React from 'react';
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import { AnimationOnScroll } from 'react-animation-on-scroll';


const allData = TestimonialData;


const TestimonialItem = ({colSize, itemShow}) => {
    return (
        <>
            {allData.slice(0, itemShow).map((data, index) => (
                    <div className={`${colSize}`} key={index}>
                        <AnimationOnScroll animateOnce={true} delay={data.delay || 100} animateIn="fadeInUp">
                            <div className="testimonial-grid">
                                {/* <span className="social-media_span">
                                    <img src={process.env.PUBLIC_URL + data.from} alt="Yelp" />
                                </span> */}
                                <p>{data.description}</p>
                                {/* <div className="author-info">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + data.authorimg} alt="Google Review" />
                                    </div>
                                    <div className="content">
                                        <span className="name">{data.authorname}</span>
                                        <span className="designation">{data.authordesig}</span>
                                    </div>
                                </div> */}
                            </div>
                        </AnimationOnScroll>
                    </div>
            ))}
        </>
    )
}

export default TestimonialItem;