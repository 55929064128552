import React, { useState, useEffect } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceProp from "../component/service/ServiceProp";
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from "../utils";
import TrackVisibility from "react-on-screen";

const allData = ServiceData;

const ServiceOne = () => {
    const [previousActiveTab, setPreviousActiveTab] = useState("");
    const [activeTab, setActiveTab] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const developmentData = allData.filter(
        (data) => slugify(data.cate ? data.cate : "") === "development"
    );
    const designData = allData.filter(
        (data) => slugify(data.cate ? data.cate : "") === "design"
    );
    const marketingData = allData.filter(
        (data) => slugify(data.cate ? data.cate : "") === "online-marketing"
    );
    const technologyData = allData.filter(
        (data) => slugify(data.cate ? data.cate : "") === "technology"
    );
    const strategyData = allData.filter(
        (data) => slugify(data.cate ? data.cate : "") === "content-strategy"
    );

    const handleScroll = (id, targetId) => {
        const el = document.getElementById(id);
        el.scrollIntoView({ behavior: "smooth" });

        // if (previousActiveTab !== "") {
        //     document.getElementById(previousActiveTab).classList.remove("current");
        // }

        // if (targetId !== "") {
        //     document.getElementById(targetId).classList.add("current");
        // }

        // setPreviousActiveTab(targetId);
        // setActiveTab(targetId);
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (previousActiveTab !== "") {
            document
                .getElementById(previousActiveTab)
                .classList.remove("current");
        }

        if (activeTab !== "") {
            document.getElementById(activeTab).classList.add("current");
        }

        setPreviousActiveTab(activeTab);
    }, [activeTab]);

    let numberToIncrmentBy =
        screenWidth < 376
            ? 450
            : screenWidth < 768
            ? 550
            : screenWidth < 1200 && screenWidth > 991
            ? 400
            : 700;
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (document.getElementById("onepagenav") !== null) {
                let scrollCheck =
                    document
                        .getElementById("onepagenav")
                        .getBoundingClientRect().top +
                        document
                            .getElementById("onepagenav")
                            .getBoundingClientRect().height +
                        numberToIncrmentBy <
                    window.scrollY;
                if (scrollCheck) {
                    document
                        .getElementById("onepagenav")
                        .classList.add("stick-to-top");
                } else {
                    document
                        .getElementById("onepagenav")
                        .classList.remove("stick-to-top");
                }
            }
        });

        return () => {
            window.removeEventListener("scroll", () => {});
        };
    }, []);

    // if user on section1Position, then set activeTab to design
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (
                document.getElementById("section1") !== null &&
                document.getElementById("section2") !== null &&
                document.getElementById("section3") !== null &&
                document.getElementById("section4") !== null &&
                document.getElementById("section5") !== null
            ) {
                if (
                    document.getElementById("section1").getBoundingClientRect()
                        .top > 0
                ) {
                    setActiveTab("");
                }
                if (
                    document.getElementById("section1").getBoundingClientRect()
                        .top < 70 &&
                    document.getElementById("section1").getBoundingClientRect()
                        .bottom > 0
                ) {
                    setActiveTab("development");
                }

                if (
                    document.getElementById("section2").getBoundingClientRect()
                        .top < 70 &&
                    document.getElementById("section2").getBoundingClientRect()
                        .bottom > 0
                ) {
                    setActiveTab("design");
                }

                if (
                    document.getElementById("section3").getBoundingClientRect()
                        .top < 70 &&
                    document.getElementById("section3").getBoundingClientRect()
                        .bottom > 0
                ) {
                    setActiveTab("marketing");
                }

                if (
                    document.getElementById("section4").getBoundingClientRect()
                        .top < 70 &&
                    document.getElementById("section4").getBoundingClientRect()
                        .bottom > 0
                ) {
                    setActiveTab("technology");
                }

                if (
                    document.getElementById("section5").getBoundingClientRect()
                        .top < 70 &&
                    document.getElementById("section5").getBoundingClientRect()
                        .bottom > 70
                ) {
                    setActiveTab("strategy");
                }
                if (
                    document.getElementById("section5").getBoundingClientRect()
                        .bottom < 70
                ) {
                    setActiveTab("");
                }
            }
        });

        return () => {
            window.removeEventListener("scroll", () => {});
        };
    }, []);

    return (
        <>
            <SEO title="Services" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Best solutions for your business"
                    paragraph="From strategy to design to development, we do it all. With flexible pricing options."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-4.png"
                />
                <div className="service-scroll-navigation-area">
                    {/* Service Nav */}
                    <TrackVisibility>
                        {({ isVisible }) => (
                            <nav
                                id="onepagenav"
                                className={`service-scroll-nav navbar onepagefixed ${
                                    !isVisible && window.scrollY !== 0
                                        ? "stick-to-top"
                                        : ""
                                }`}
                            >
                                <div className="container">
                                    <ul className="nav nav-pills">
                                        <li
                                            className="nav-item"
                                            id="development"
                                        >
                                            <a
                                                className="nav-link pointer"
                                                onClick={() =>
                                                    handleScroll(
                                                        "section1",
                                                        "development"
                                                    )
                                                }
                                            >
                                                Development
                                            </a>
                                        </li>
                                        <li className="nav-item" id="design">
                                            <a
                                                className="nav-link pointer"
                                                onClick={() =>
                                                    handleScroll(
                                                        "section2",
                                                        "design"
                                                    )
                                                }
                                            >
                                                Design
                                            </a>
                                        </li>
                                        <li className="nav-item" id="marketing">
                                            <a
                                                className="nav-link pointer"
                                                onClick={() =>
                                                    handleScroll(
                                                        "section3",
                                                        "marketing"
                                                    )
                                                }
                                            >
                                                Online Marketing
                                            </a>
                                        </li>
                                        <li
                                            className="nav-item"
                                            id="technology"
                                        >
                                            <a
                                                className="nav-link pointer"
                                                onClick={() =>
                                                    handleScroll(
                                                        "section4",
                                                        "technology"
                                                    )
                                                }
                                            >
                                                Technology
                                            </a>
                                        </li>
                                        <li className="nav-item" id="strategy">
                                            <a
                                                className="nav-link pointer"
                                                onClick={() =>
                                                    handleScroll(
                                                        "section5",
                                                        "strategy"
                                                    )
                                                }
                                            >
                                                Content Strategy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        )}
                    </TrackVisibility>

                    <br />

                    <div className="section section-padding" id="section1">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Development"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp
                                    colSize="col-lg-4 col-md-6"
                                    serviceStyle="service-style-2"
                                    serviceData={developmentData}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="section section-padding bg-color-light"
                        id="section2"
                    >
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Design"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp
                                    colSize="col-lg-4 col-md-6"
                                    serviceStyle="service-style-2"
                                    serviceData={designData}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding" id="section3">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Online Marketing"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp
                                    colSize="col-lg-4 col-md-6"
                                    serviceStyle="service-style-2"
                                    serviceData={marketingData}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="section section-padding bg-color-light"
                        id="section4"
                    >
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Technology"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp
                                    colSize="col-lg-4 col-md-6"
                                    serviceStyle="service-style-2"
                                    serviceData={technologyData}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding" id="section5">
                        <div className="container">
                            <SectionTitle
                                subtitle="Service"
                                title="Content strategy"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp
                                    colSize="col-lg-4 col-md-6"
                                    serviceStyle="service-style-2"
                                    serviceData={strategyData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default ServiceOne;
