import React from "react";
import FormOne from "../contact/FormOne";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";

const AboutTwo = ({ detailsService }) => {
  return (
    <div className='section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='why-choose-us'>
              <div className='section-heading heading-left'>
                <span className='subtitle'>{detailsService.subtitle || "About Us"}</span>
                <h3 className='title'>{detailsService.title || "Why branding matters?"}</h3>
                <p>
                  {
                    detailsService.longDescription || `Branding is the process of giving a meaning to specific
                  organization, company, products or services by creating and
                  shaping a brand in consumers’ minds. It is a strategy designed
                  by organizations to help people to quickly identify and
                  experience their brand, and give them a reason to choose their
                  products over the competition’s, by clarifying what this
                  particular brand is and is not.`
                  }
                </p>
              </div>
              <Accordion defaultActiveKey='1'>

                  {
                    detailsService && detailsService.strategy && (
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>
                          <FaCompress /> Strategy
                        </Accordion.Header>
                        <Accordion.Body>
                          {detailsService && detailsService.strategy}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                
                {
                  detailsService && detailsService.design && (
                  <Accordion.Item eventKey='2'>
                    <Accordion.Header>
                      <FaCode /> Design
                    </Accordion.Header>
                    <Accordion.Body>
                      {detailsService && detailsService.design}
                    </Accordion.Body>
                  </Accordion.Item>
                  )
                }
                
                {
                  detailsService && detailsService.development && (
                  <Accordion.Item eventKey='3'>
                    <Accordion.Header>
                      <FaGlobe /> Development
                    </Accordion.Header>
                    <Accordion.Body>
                      {detailsService && detailsService.development}
                    </Accordion.Body>
                  </Accordion.Item>
                  )
                }
              </Accordion>
            </div>
          </div>
          <div className='col-xl-5 col-lg-6 offset-xl-1'>
            <div className='contact-form-box shadow-box mb--30'>
              <h3 className='title'>Get a free Keystroke quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
