import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const VideoOne = ({ detailsProject }) => {

    const [toggler, setToggler] = useState(false);

    return (
        <>
            <div className="section section-padding-equal bg-color-light">
                <div className="container">
                    {/* <div className="section-heading heading-light heading-center mb--30">
                        <h2 className="title">Project Videos</h2>
                    </div> */}
                    <div className="about-expert">
                        <AnimationOnScroll animateIn="zoomIn" animateOnce={true} delay={100}>
                        <div className="thumbnail">
                            {
                                detailsProject.videoThumbnail ? 
                                <img src={process.env.PUBLIC_URL + detailsProject.videoThumbnail} alt="Video Thumbnail" />
                                :
                                <img src={process.env.PUBLIC_URL + "/images/about/about-1.png"} alt="Video Thumbnail" />

                            }
                            <div className="popup-video">
                                <button className="play-btn" onClick={ () => setToggler(!toggler) }><FaPlay /></button>
                            </div>
                        </div>
                        </AnimationOnScroll>
                    </div>
                </div>
                <ul className="shape-group-16 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>
        </div>
        <FsLightbox toggler={ toggler } sources={ [ ...detailsProject.videoURLs ] } />
    </>
    )
}


export default VideoOne;