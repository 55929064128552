import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Pagination, EffectCube, Autoplay } from "swiper";

SwiperCore.use([Mousewheel, Pagination, EffectCube, Autoplay]);

const SwiperCarouselMobile = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [swiperInstance, setSwiperInstance] = useState();
    const swiperRef = useRef(null);

  return (
    // <div className="portfolio-swiper d-flex items-center">
    <div className="">
        <Swiper
            ref={swiperRef}
            slidesPerView={1}
            // pagination={{
            //     clickable: true,
            // }}
            autoplay={{
                delay: 2500,
            }}
            effect="cube"
            initialSlide={1}
            centeredSlides={true}
            grabCursor={true}
            modules={[Mousewheel, Pagination]}
            onSwiper={setSwiperInstance}
            onSlideChange={(e) => {
                setActiveIndex(e.activeIndex);
            }}
        >
            {
                images.map((image, index) => (
                    <SwiperSlide 
                        key={index}
                        onClick={() => {
                        setActiveIndex(index);
                            swiperRef.current.swiper.slideTo(index);
                        }}
                    >
                        <div
                            // className={activeIndex === index ? "portfolio_swiper_active" : "portfolio_swiper_not_active"} 
                        >
                            <img src={image} alt="image" 
                                style={{ borderRadius: "38px", overflow: "hidden" }}
                                // className={activeIndex === index ? "w-100 h-100 shadow-portfolio" : "opacity-50"}
                            />
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </div>
            
  )
}

export default SwiperCarouselMobile