import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck } from "react-icons/fa";
import { AnimationOnScroll } from "react-animation-on-scroll";

const getPriceingData = PricingData;

const billed = [
    {
        id: 1,
        label: "Website",
    },
    {
        id: 2,
        label: "Mobile",
    },
    {
        id: 3,
        label: "Blockchain",
    },
    // {
    //     id: 4,
    //     label: "UI/UX"
    // },
    // {
    //     id: 5,
    //     label: "Custom"
    // }
];

const PricingOne = () => {
    const navigate = useNavigate();

    const [isActive, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
    }, []);

    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
    };

    return (
        <>
            <div className="pricing-billing-duration">
                <ul>
                    {billed.map((data) => (
                        <li className="nav-item" key={data.id}>
                            <button
                                onClick={handleChange}
                                className={`nav-link ${
                                    data.label.toLocaleLowerCase() === isActive
                                        ? "active"
                                        : ""
                                }`}
                            >
                                {data.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="row">
                {getPriceingData.map((data, index) => (
                    <div
                        className="col-lg-4"
                        style={{ cursor: "pointer" }}
                        key={index}
                    >
                        <AnimationOnScroll
                            animateIn="fadeInUp"
                            animateOnce={true}
                            delay={index * 100}
                        >
                            <div
                                className="pricing-table pricing-borderd"
                                onClick={() => {
                                    navigate("/contact");
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="pricing-header">
                                    <h3 className="title">{data.title}</h3>
                                    <span className="subtitle">
                                        {data.subtitle}
                                    </span>
                                    {/* <div className="price-wrap">
                                        <div className="yearly-pricing">
                                            <span className="amount">
                                                {
                                                    isActive === "website" ? data.websitePrice : isActive === "mobile" ? data.mobilePrice : isActive === "blockchain" ? data.blockchainPrice : isActive === "ui/ux" ? data.uiuxPrice : data.customPrice
                                                }
                                            </span>
                                            <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration }</span>
                                            <span className="duration">/project</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="pricing-btn">
                                        <Link to="#" className="axil-btn btn-large btn-borderd">Get Started Today</Link>
                                    </div> */}
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-unstyled">
                                        {data.facility.map((data, index) => (
                                            <ul
                                                className="list-unstyled"
                                                key={index}
                                            >
                                                {data.title == isActive &&
                                                    data.offer.map(
                                                        (off, ind) => (
                                                            <li key={ind}>
                                                                <FaCheck />{" "}
                                                                {off}
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PricingOne;
