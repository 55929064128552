import React from 'react';
import FormOne from '../contact/FormOne';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { WEBSITE_ACRONYM } from '../../data/website/websiteData';

const AboutOne = () => {
    return (
      <section
        className='section section-padding-equal bg-color-light'
        id='about_us'
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <AnimationOnScroll
                animateOnce={true}
                delay={100}
                animateIn='fadeInUp'
              >
                <div className='about-us'>
                  <div className='section-heading heading-left mb-0'>
                    <span className='subtitle'>About Us</span>
                    <h2 className='title mb--40'>
                      We're not just developers, we're dreamers
                    </h2>
                    <p>
                      Discover the passion and expertise behind Nova Dev
                      Solutions.
                    </p>
                    {/* <p>
                      Nulla pharetra hendrerit mi quis dapibus. Quisque luctus,
                      tortor a venenatis fermentum, est lacus feugiat nisl, id
                      pharetra odio enim eget libero.{" "}
                    </p> */}
                  </div>
                </div>
              </AnimationOnScroll>
            </div>

            <div className='col-xl-5 col-lg-6 offset-xl-1'>
              <AnimationOnScroll
                animateOnce={true}
                delay={100}
                animateIn='fadeInUp'
              >
                <div className='contact-form-box'>
                  <h3 className='title'>
                    Get a free {WEBSITE_ACRONYM} quote now
                  </h3>
                  <FormOne />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
        <ul className='shape-group-6 list-unstyled'>
          <li className='shape shape-1'>
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
              alt='Bubble'
            />
          </li>
          <li className='shape shape-2'>
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
              alt='line'
            />
          </li>
          <li className='shape shape-3'>
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
              alt='line'
            />
          </li>
        </ul>
      </section>
    );
}

export default AboutOne;