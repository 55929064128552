import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import { useAlert } from "react-alert";

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    );
};

const Failed = () => {
    return (
        <>
            <Alert variant="danger" className="error-msg">
                Something went wrong.
            </Alert>

            <Alert variant="danger" className="error-msg">
                Please contact us directly at{" "}
                <a href="mailto:novadevsol@gmail.com">novadevsol@gmail.com</a>
            </Alert>
        </>
    );
};

const FormTwo = () => {
    const alert = useAlert();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef();

    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        if (name === "" || email === "" || phone === "" || message === "") {
            alert.show("Please fill in all fields");
            return;
        }
        if (name.length < 3) {
            alert.show("Name must be at least 3 characters");
            return;
        }
        if (name.length > 30) {
            alert.show("Name must be less than 20 characters");
            return;
        }
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(email)) {
            alert.show("Please enter a valid email address");
            return;
        }

        if (phone.length < 10) {
            alert.show("Phone number must be at least 10 digits");
            return;
        }
        if (phone.length > 15) {
            alert.show("Phone number must be less than 15 digits");
            return;
        }

        if (message.length < 10) {
            alert.show("Message must be at least 10 characters");
            return;
        }

        if (message.length > 500) {
            alert.show("Message must be less than 500 characters");
            return;
        }

        // emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });
        //   form.current.reset();
        showresult(true);

        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setTimeout(() => {
            showresult(false);
        }, 5000);
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>Name</label>
                <input
                    type="text"
                    className="form-control"
                    name="contact-name"
                    placeholder='e.g. "John Doe"'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input
                    type="email"
                    className="form-control"
                    name="contact-email"
                    placeholder="info@gmil.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Phone</label>
                <input
                    type="tel"
                    className="form-control"
                    name="contact-phone"
                    placeholder='+1 234 567 890"'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className="form-group mb--40">
                <label>How can we help you?</label>
                <textarea
                    className="form-control"
                    name="contact-message"
                    rows="4"
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
            </div>
            <div className="form-group">
                <button
                    type="submit"
                    className="axil-btn btn-fill-primary btn-fluid btn-primary"
                    name="submit-btn"
                >
                    Get Pricing Now
                </button>
            </div>
            {/* <div className="form-group">{result ? <Result /> : null}</div> */}
            <div className="form-group">{result ? <Failed /> : null}</div>
        </form>
    );
};

export default FormTwo;
