import React from "react";
import { useNavigate } from "react-router-dom";
import ExcecutiveMembers from "../../data/team/ExecutiveMembers.json";
import { slugify } from "../../utils";

const allData = ExcecutiveMembers;

const TeamOne = () => {
    const navigate = useNavigate();
    return (
        <div className="section section-padding bg-color-dark pb--70 pb_lg--20 pb_md--0">
            <div className="container">
                <div className="section-heading heading-light">
                    <span className="subtitle">Team</span>
                    <h2 className="title mb--50">Excecutive Members</h2>
                    <p>
                        We are a team of passionate individuals who love to make
                        complex things simple.
                    </p>
                </div>
                <div className="row">
                    {allData.map((data) => (
                        <div className="col-xl-3 col-sm-6" key={data.id}>
                            <div className="team-grid">
                                <div className="thumbnail">
                                    <a
                                        className="pointer"
                                        onClick={() => {
                                            navigate(
                                                process.env.PUBLIC_URL +
                                                    `/team-details/${slugify(
                                                        data.title
                                                    )}`
                                            );
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                data.thumb
                                            }
                                            alt={data.title}
                                        />
                                    </a>
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <a
                                            className="pointer"
                                            onClick={() => {
                                                navigate(
                                                    process.env.PUBLIC_URL +
                                                        `/team-details/${slugify(
                                                            data.title
                                                        )}`
                                                );
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            {data.title}
                                        </a>
                                    </h4>
                                    <span
                                        className="designation"
                                        dangerouslySetInnerHTML={{
                                            __html: data.designation,
                                        }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/images/others/circle-1.png"
                        }
                        alt="Circle"
                    />
                </li>
                <li className="shape shape-2">
                    <img
                        src={
                            process.env.PUBLIC_URL + "/images/others/line-3.png"
                        }
                        alt="Circle"
                    />
                </li>
                <li className="shape shape-3">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/images/others/bubble-5.png"
                        }
                        alt="Circle"
                    />
                </li>
            </ul>
        </div>
    );
};

export default TeamOne;
