import React, { useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import 'bootstrap/dist/css/bootstrap.min.css';

// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';

// Blog Import
// import Blog from './pages/Blog';
// import BlogCategory from './pages/Category';
// import BlogArchive from './pages/Archive';
// import BlogDetails from './pages/BlogDetails';

// Service 
import ServiceOne from './pages/ServiceOne';
import ServiceDetails from './pages/ServiceDetails';

// Project 
import ProjectGridThree from './pages/ProjectGridThree';
import ProjectDetails from './pages/ProjectDetails';

// Pages 
import AboutUs from './pages/AboutUs';
import TeamDetails from './pages/TeamDetails';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';
import './App.css';

// Context Import
import { BlogSearchProvider } from './contexts/BlogSearchContext';

const App = () => {
	useEffect(() => {
		const darkMode = localStorage.getItem('darkMode');
		if (darkMode === 'true') {
			document.querySelector('body').classList.add('active-dark-mode');
		}else{
			document.querySelector('body').classList.remove('active-dark-mode');
		}

	}, []);
  return (
	<BlogSearchProvider>
		<Router>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />}/>

				{/* Blogs */}
				{/* <Route path={process.env.PUBLIC_URL + "/blogs"} element={<Blog />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/> */}

				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/services/"} element={<ServiceOne />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>

				{/* Project  */}
				<Route path={process.env.PUBLIC_URL + "/portfolio"} element={<ProjectGridThree />}/>
				<Route path={process.env.PUBLIC_URL + "/portfolio-details/:slug"} element={<ProjectDetails />}/>

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>

				{/* Error Page */}
				<Route path="*" element={<ErrorPage />}/>

			</Routes>
			<ScrollToTop smooth top={105} className="scrollToTop" color='#fff' height='20px' width='20px' />
		</Router>
	</BlogSearchProvider>
  )
}

export default App;
