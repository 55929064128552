import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import ContactLocation from "../component/contact/ContactLocation";
import FaqOne from "../component/faq/FaqOne";

import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";

import {
    WEBSITE_ACRONYM,
    WEBSITE_EMAILS,
    WEBSITE_PHONE_NUMBERS,
} from "../data/website/websiteData";

const Contact = () => {
    const alert = useAlert();
    return (
        <>
            <SEO title="Contact" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne title="Contact" page="Contact" />

                <div className="section section-padding" id="contact_us_form">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-form-box shadow-box mb--30">
                                    <h3 className="title">
                                        Get a free {WEBSITE_ACRONYM} quote now
                                    </h3>
                                    <FormTwo />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                    <h4 className="title">Phone</h4>
                                    <p>
                                        Our customer care is open from Mon-Fri,
                                        10:00 am to 6:00 pm
                                    </p>
                                    <ReactTooltip id="copyPhone" />
                                    <CopyToClipboard
                                        text={WEBSITE_PHONE_NUMBERS[0]}
                                        onCopy={() =>
                                            alert.show("Copied to clipboard!")
                                        }
                                    >
                                        <h4
                                            className="phone-number"
                                            data-tip="Click to Copy and Initiate Call"
                                            data-for="copyPhone"
                                        >
                                            <a
                                                href={`tel:${WEBSITE_PHONE_NUMBERS[0]}`}
                                            >
                                                {WEBSITE_PHONE_NUMBERS[0]}
                                            </a>
                                        </h4>
                                    </CopyToClipboard>
                                </div>
                                <div className="contact-info mb--30">
                                    <h4 className="title">Email</h4>
                                    <p>
                                        Our support team will get back to in
                                        48-h during standard business hours.
                                    </p>
                                    <ReactTooltip id="copyEmail" />
                                    <CopyToClipboard
                                        text={WEBSITE_EMAILS[0]}
                                        onCopy={() =>
                                            alert.show("Copied to clipboard!")
                                        }
                                    >
                                        <h4
                                            className="phone-number"
                                            data-tip="Click to Copy and Initiate Email"
                                            data-for="copyEmail"
                                        >
                                            <a
                                                href={`mailto:${WEBSITE_EMAILS[0]}`}
                                            >
                                                {WEBSITE_EMAILS[0]}
                                            </a>
                                        </h4>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-1">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/others/bubble-2.png"
                                }
                                alt="Bubble"
                            />
                        </li>
                        <li className="shape shape-2">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/others/bubble-1.png"
                                }
                                alt="Bubble"
                            />
                        </li>
                        <li className="shape shape-3">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/others/circle-3.png"
                                }
                                alt="Circle"
                            />
                        </li>
                    </ul>
                </div>

                <FaqOne />

                <div className="section section-padding bg-color-dark overflow-hidden">
                    <div className="container">
                        <SectionTitle
                            subtitle="Find Us"
                            title="Our office"
                            description=""
                            textAlignment="heading-light-left"
                            textColor=""
                        />
                        <div className="row">
                            <ContactLocation />
                        </div>
                    </div>
                    <ul className="shape-group-11 list-unstyled">
                        <li className="shape shape-1">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/others/line-6.png"
                                }
                                alt="line"
                            />
                        </li>
                        <li className="shape shape-2">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/others/circle-3.png"
                                }
                                alt="line"
                            />
                        </li>
                    </ul>
                </div>

                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    );
};

export default Contact;
