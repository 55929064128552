import React from 'react';
import { useNavigate } from 'react-router-dom';


const Logo = ({limage, dimage, simage}) => {
    const navigate = useNavigate();
    return (
        <a
            className="pointer" 
            onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
            }}
        >
            <img className="light-version-logo" src={process.env.PUBLIC_URL + limage} width={175} height={40} alt="logo" />
            <img className="dark-version-logo" src={process.env.PUBLIC_URL + dimage} width={175} height={40} alt="logo" />
            <img className="sticky-logo" src={process.env.PUBLIC_URL + simage} width={175} height={40} alt="logo" />
        </a>
    )
}


export default Logo;