import React, { useState } from 'react';
import BlogSearchContext from './BlogSearchContext';

const BlogSearchProvider = ({ children }) => {
    const [searchBlog, setSearchBlog] = useState('');

    const [similarPosts, setSimilarPosts] = useState(true);

    return (
        <BlogSearchContext.Provider value={{ searchBlog, setSearchBlog, similarPosts, setSimilarPosts }}>
            {children}
        </BlogSearchContext.Provider>
    )
}

export default BlogSearchProvider;

