import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    const navigate = useNavigate();
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li>
                    <a className='pointer'
                        onClick={() => {
                            navigate("/");
                            window.scrollTo(0, 0);
                        }}
                        style={{
                            fontWeight: '500',
                        }}
                    >Home</a>
                </li>
                <li className="menu-item-has-children">
                    <a
                        className='pointer'
                        onClick={() => {
                            navigate("/services");
                            window.scrollTo(0, 0);
                        }}
                    >
                        Services
                    </a>
                </li>

                <li>
                    <a
                        className='pointer'
                        onClick={() => {
                            navigate("/portfolio");
                            window.scrollTo(0, 0);
                        }}
                    >
                        Portfolio
                    </a>
                </li>
                {/* <li className="menu-item-has-children">
                    <Link to="#">Blog <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/blog-details/1"}>Standard Post</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/blog-details/2"}>Gallery Post</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/blog-details/3"}>Video Post</Link></li>
                    </ul>
                </li> */}
                {/* <li>
                    <a
                        className='pointer'
                        onClick={() => {
                            navigate("/blogs");
                            window.scrollTo(0, 0);
                        }}
                    >
                        Blog
                    </a>
                </li> */}
                <li>
                    <a
                        className='pointer'
                        onClick={() => {
                            navigate("/about-us");
                            window.scrollTo(0, 0);
                        }}
                    >
                        About Us
                    </a>
                </li>
                <li><a
                        className='pointer'
                        onClick={() => {
                            navigate("/contact");
                            window.scrollTo(0, 0);
                        }}
                        style={{
                            fontWeight: '500',
                        }}
                    >
                        Contact
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;