import React from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from '../component/video/VideoOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
        <>
        <SEO title="Project Details"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerTwo 
                title={detailsProject.title}
                paragraph ={detailsProject.excerpt}
                mainThumb={detailsProject.image}
                images={detailsProject.carouselImages}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <AnimationOnScroll animateOnce={true} delay={100} animateIn="fadeInDown">
                                <span className="subtitle">
                                    {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )}
                                </span>
                                <h3 className="title">{detailsProject.title}</h3>
                                </AnimationOnScroll>
                            </div>
                            <AnimationOnScroll animateOnce={true} delay={150} animateIn="fadeInDown">
                                {detailsProject.body.map((para, index) => (
                                    <p key={index} dangerouslySetInnerHTML={{__html: para}}></p>
                                ))}
                                
                                {
                                    detailsProject.projectURL &&
                                    <a 
                                        className="axil-btn btn-fill-primary pointer"
                                        href={detailsProject.projectURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span className="button-text">Live Preview</span>
                                    </a>
                                }
                            </AnimationOnScroll>
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <AnimationOnScroll animateOnce={true} delay={100} animateIn="fadeInUp">
                                    <div className="section-heading heading-left">
                                        <h3 className="title">We delivered</h3>
                                        <p>Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business.</p>
                                    </div>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateOnce={true} delay={100} animateIn="fadeInUp">
                                    <Accordion defaultActiveKey="1">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                                                <Accordion.Body>
                                                    {detailsProject && detailsProject.strategy}
                                                </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><FaCode /> Design</Accordion.Header>
                                                <Accordion.Body>
                                                    {detailsProject && detailsProject.design}
                                                </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                                <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                                <Accordion.Body>
                                                    {detailsProject && detailsProject.development}
                                                </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                detailsProject.videoURLs &&
                    <VideoOne detailsProject={detailsProject} />
            }
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProjectDetails;