import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { WEBSITE_NAME, WEBSITE_EMAILS } from "../data/website/websiteData";

const TermsOfUse = () => {
    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="privacy-policy-content">
                                        <p>
                                            Welcome to {WEBSITE_NAME}
                                            <br />
                                            These Terms of Use govern your use
                                            of our website form a binding
                                            contractual agreement between you,
                                            the user of the Site and us.
                                            <br />
                                            Your agreement to comply with and be
                                            bound by these terms and conditions
                                            is deemed to occur upon your first
                                            use of the Site. If you do not agree
                                            to be bound by these Terms of Use,
                                            you should stop using the Site
                                            immediately.
                                        </p>
                                        <h4>1. Information on the Site</h4>
                                        <p>
                                            The Site is intended to provide
                                            general information and is not meant
                                            to constitute advice on which
                                            reliance should be placed. We do not
                                            warrant or represent that the
                                            information on the Site is accurate,
                                            complete, or up-to-date and do not
                                            accept any liability or
                                            responsibility arising in any way
                                            from errors or omissions.
                                        </p>
                                        <h4>2. Intellectual Property</h4>
                                        <p>
                                            All content included on the Site is
                                            the property of {WEBSITE_NAME} and
                                            is protected by applicable copyright
                                            and trademark law. You agree not to
                                            reproduce, distribute, modify,
                                            display, perform, publish, license,
                                            create derivative works from, offer
                                            for sale, or use (except as
                                            explicitly authorized under these
                                            Terms of Use) content and
                                            information contained on or obtained
                                            from or through the Site.
                                        </p>
                                        <h4>3. Links to Other Websites</h4>
                                        <p>
                                            The Site may contain links to third
                                            party websites. Such links are
                                            provided for your convenience only.
                                            We do not control such websites and
                                            are not responsible for their
                                            contents. The mere inclusion of such
                                            links does not imply any
                                            endorsement of the material on those
                                            websites or any association with
                                            their operators. If you decide to
                                            access any of the third party
                                            websites linked to the Site, you do
                                            so entirely at your own risk.
                                        </p>
                                        <h4>4. Limitation of Liability</h4>
                                        <p>
                                            To the maximum extent permitted by
                                            law, we accept no liability for any
                                            direct or indirect loss or damage,
                                            foreseeable or otherwise,
                                            including any indirect, consequential,
                                            special, or exemplary damages
                                            arising from the use of the Site or
                                            any information contained therein.
                                            Users should be aware that they use
                                            the Site and its content at their
                                            own risk.
                                        </p>
                                        <h4>5. Changes to the Terms of Use</h4>
                                        <p>
                                            We reserve the right to change these
                                            Terms of Use at any time. If you
                                            continue to use the Site after the
                                            date on which the change comes into
                                            effect, your use of the Site
                                            indicates your agreement to be bound
                                            by the new Terms of Use.
                                        </p>
                                        <h4>6. Accessing the Site</h4>
                                        <p>
                                            We cannot guarantee that the Site
                                            will be uninterrupted or error-free.
                                            We reserve the right to suspend or
                                            withdraw the availability of the
                                            Site and/or any of the services on
                                            it without notice at any time and
                                            accept no liability for
                                            unavailability of the Site at any
                                            time or for any period.
                                        </p>
                                        <h4>7. Privacy</h4>
                                        <p>
                                            Use of the Site is also governed by
                                            our Privacy Policy, which is
                                            incorporated into these Terms of Use
                                            by this reference. To view the
                                            Privacy Policy, please click on the
                                            link above.
                                        </p>
                                        <h4>8. Disclaimers</h4>
                                        <p>
                                            The Site is provided on an “as-is”
                                            and “as available” basis and we do
                                            not guarantee that the Site will be
                                            fault free. We do not accept
                                            liability for any errors or
                                            omissions and reserve the right to
                                            change information, specifications,
                                            and descriptions of listed goods,
                                            products, and services.
                                        </p>
                                        <h4>9. Governing Law</h4>
                                        <p>
                                            These Terms of Use shall be governed
                                            by and construed in accordance with
                                            the law of the United States and you
                                            hereby submit to the exclusive
                                            jurisdiction of the United States
                                            courts.
                                        </p>
                                        <h4>Contact Us</h4>
                                        <p>
                                            If you have any questions about
                                            these Terms of Use, please contact
                                            us at{" "}
                                            <a
                                                href={`mailto:${WEBSITE_EMAILS[0]}`}
                                            >
                                                {WEBSITE_EMAILS[0]}
                                            </a>
                                            .
                                        </p>

                                        <p>
                                            <strong>
                                                Last updated: 2023-01-01
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default TermsOfUse;
