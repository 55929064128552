import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <AnimationOnScroll animateIn="slideInLeft" animateOnce={true} >
                            <img src={process.env.PUBLIC_URL + "/images/about/about-2.png"} alt="thumbnail" />
                        </AnimationOnScroll>
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <AnimationOnScroll animateIn="slideInRight" animateOnce={true} >
                            <span className="subtitle">Our Team</span>
                            <h2>Alone we can do so little; together we can do so much.</h2>  
                            <p>Behind every great product, there's a great team - meet the masterminds behind your success story.</p>
                        </AnimationOnScroll>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;